<dx-chart [dataSource]="ChartData" #EncodedTrackChart>
  <dxi-series valueField="BillableHours" name="Billable" color="orange" />
  <dxi-series valueField="BTFAdminHours" name="BTF-Admin" color="green" />
  <dxi-series valueField="BTFIntelHours" name="BTF-Intel" color="blue" />
  <dxi-series valueField="NonBillableHours" name="Non-Billable" color="grey" />
  <dxi-value-axis
    [showZero]="true">
    <dxi-constant-line [value]="EmployeeTargetValue" color="#fc3535" dashStyle="dash" [width]="2" [extendAxis]="true"></dxi-constant-line>
  </dxi-value-axis>
  <dxo-common-series-settings
    #commonSeries
    [argumentField]="ArgumentField"
    [type]="'stackedBar'"
  >
    <dxo-point [visible]="true" />
  </dxo-common-series-settings>
  <dxo-margin [bottom]="20" />
  <dxo-argument-axis [valueMarginsEnabled]="false" />
  <dxo-export [enabled]="true" />
  <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" />
  <dxo-tooltip [enabled]="true" />
</dx-chart>
