import { Component, OnInit, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { share } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

import { DxPieChartModule } from 'devextreme-angular/ui/pie-chart';
import { DxChartModule } from 'devextreme-angular/ui/chart';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxFunnelModule } from 'devextreme-angular/ui/funnel';
import { DxBulletModule } from 'devextreme-angular/ui/bullet';
import { DxLoadPanelModule } from 'devextreme-angular/ui/load-panel';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CardAnalyticsModule } from 'src/app/components/library/card-analytics/card-analytics.component';
import { ToolbarAnalyticsModule } from 'src/app/components/utils/toolbar-analytics/toolbar-analytics.component';
import { Dates } from 'src/app/types/resource';
import {
  Sales,
  SalesByState,
  SalesByStateAndCity,
  SalesOrOpportunitiesByCategory,
} from 'src/app/types/analytics';
import { ApplyPipeModule } from 'src/app/pipes/apply.pipe';
import { FinancialNumberKpiModule } from 'src/app/components/utils/financial-number-kpi/financial-number-kpi.component';
import { SalesAnalyticsService } from 'src/app/services';
import { RealizedTargetChartModule } from '../../../components/utils/realized-target-chart/realized-target-chart.component';
import { Statistics } from 'src/app/types/invoicing/reporting/Statistics';
import { MonthlyGoalsCardModule } from '../../../components/utils/monthly-goals-card/monthly-goals-card.component';
import { BusinessStatistics } from 'src/app/types/invoicing/reporting/BusinessStatistics';
import { WipCardModule } from '../../../components/utils/wip-card/wip-card.component';
import { FinancialValutaKpiModule } from 'src/app/components/utils/financial-valuta-kpi/financial-valuta-kpi.component';
import { FinancialTotalKpiModule } from 'src/app/components/utils/financial-total-kpi/financial-total-kpi.component';

type DashboardData =
  | SalesOrOpportunitiesByCategory
  | Sales
  | SalesByState
  | SalesByStateAndCity
  | null;
type DataLoader = (startDate: string, endDate: string) => Observable<Object>;

@Component({
  templateUrl: './analytics-sales-report.component.html',
  styleUrls: ['./analytics-sales-report.component.scss'],
  providers: [SalesAnalyticsService],
})
export class AnalyticsSalesDashboardComponent implements OnInit {

  // Start with the dates put on this year. 
  startDate: string =  new Date(new Date().getFullYear(), 0, 1).toDateString();
  endDate: string = new  Date(new Date().getFullYear(), 11, 31).toDateString();


  nrOfInvoices = 50;
  invoices: number = 0;

  analyticsPanelItems = [
    {
      text: 'Last Year',
      startDate: new Date(new Date().getFullYear() - 1, 0, 1).toDateString(),
      endDate: new Date(new Date().getFullYear() - 1, 11, 31).toDateString(),
      key: 1,
      cummulative: false,
    },
    {
      text: 'This Year',
      startDate: new Date(new Date().getFullYear(), 0, 1).toDateString(),
      endDate: new Date(new Date().getFullYear(), 11, 31).toDateString(),
      key: 2,
      cummulative: false,
    },
  ];

  statistics: Statistics[] = null;
  monthlyBusinessStats: BusinessStatistics[] = null;
  sales: number = null;
  margin: number = null;
  marginperc: number = null;
  wipMarginTotal: number = null;
  salesByState: SalesByState = null;
  salesByCategory: SalesByStateAndCity = null;

  isLoading: boolean = true;

  period: string;

  constructor(private salesSvc: SalesAnalyticsService) {}

  selectionChange(dates: Dates) {
    this.period = `period selected: ${new Date(dates.startDate).getFullYear()}`;
    this.startDate = dates.startDate;
    this.endDate = dates.endDate;
    this.loadData(dates.startDate, dates.endDate);
  }

  customizeSaleText(arg: { percentText: string }) {
    return arg.percentText;
  }

  loadData = (startDate: string, endDate: string) => {
    this.isLoading = true;
    const tasks: Observable<object>[] = [
      ['invoices', this.salesSvc.getInvoicesByPeriod],
      ['sales', this.salesSvc.getSalesByPeriod],
      ['margin', this.salesSvc.getMarginByPeriod],
    ].map(([dataName, loader]: [string, DataLoader]) => {
      const loaderObservable = loader(startDate, endDate).pipe(share());

      loaderObservable.subscribe((result: DashboardData) => {
        this[dataName] = result;
      });

      return loaderObservable;
    });

    forkJoin(tasks).subscribe(() => {
      this.marginperc = this.calculateMarginPercentage(this.margin, this.sales);
      this.getWipMarginTotal();
      this.isLoading = false;
    });
  };

  ngOnInit(): void {
    // start of the year (first day) and end of the year (last day)
    const startDate = this.analyticsPanelItems[1].startDate;
    const endDate = this.analyticsPanelItems[1].endDate;

    //define text for the period
    this.period = `period selected: ${new Date(startDate).getFullYear()}`;

    this.loadData(startDate, endDate);
  }

  onChartMarginPointClick = (e: any) => {
    const monthNumber = e.target.data.MonthNumber;
    const year = e.target.data.Year;

    // Start date is the first day of the month
    const startDate = new Date(year, monthNumber - 1, 1);

    // End date is the last day of the month
    const endDate = new Date(year, monthNumber, 0);

    const startDateStr = startDate.toDateString();
    const endDateStr = endDate.toDateString();

    const monthLabel = new Date(year, monthNumber - 1, 10).toLocaleString(
      'default',
      { month: 'long' }
    );
    this.period = `period selected: ${monthLabel} - ${year}`;

    this.loadData(startDateStr, endDateStr);
  };

  public calculateMarginPercentage(margin: number, sales: number): number {
    if (sales === 0) {
      return 0;
    } else {
      const ratio = margin / sales;
      const percentage = parseFloat((ratio * 100).toFixed(2));
      return percentage;
    }
  }

  getWipMarginTotal() {
    this.salesSvc.getWIPMarginAmount().subscribe((data: any) => {
      this.wipMarginTotal = data.Margin;
    });
  }
}

@NgModule({
  providers: [],
  exports: [],
  declarations: [AnalyticsSalesDashboardComponent],
  imports: [
    DxScrollViewModule,
    DxDataGridModule,
    DxBulletModule,
    DxFunnelModule,
    DxPieChartModule,
    DxChartModule,
    CardAnalyticsModule,
    ToolbarAnalyticsModule,
    DxLoadPanelModule,
    ApplyPipeModule,
    FinancialNumberKpiModule,
    FinancialValutaKpiModule,
    CommonModule,
    RealizedTargetChartModule,
    MonthlyGoalsCardModule,
    WipCardModule,
    FinancialTotalKpiModule,
  ],
})
export class AnalyticsSalesDashboardModule {}
