<dx-scroll-view class="view-wrapper-scroll">
  <dx-load-panel [visible]="loading" />
  <div class="view-wrapper">
    <div class="dashboard-header">
      <titlebar titleText="Team Dashboard: " />
      <dx-select-box
        [dataSource]="activeTeams"
        [value]="TeamId"
        displayExpr="TeamLabel"
        valueExpr="TeamId"
        (onValueChanged)="onTeamChanged($event)"
        [searchEnabled]="true"
      />
      <dx-tabs
      [width]="(screen.xSmallScreenChanged | async) ? 150 : 'auto'"
      [showNavButtons]="false"
      [scrollByContent]="true"
      [selectedItemKeys]="[2]"
      keyExpr="key"
      [items]="panelItems"
      (onItemClick)="selectionChange($event)"
    />
    </div>
    <div class="separator"></div>
    <div class="cards">
      <app-kpi-toolbar [TeamId]="TeamId" [Year]="this.Year" />
    </div>
    <div class="cards">
      <div class="reporting-graphs">
        <dx-tab-panel
          class="dx-theme-background-color"
          width="100%"
          [animationEnabled]="true"
          [swipeEnabled]="true"
          [tabsPosition]="'top'"
          [items]="graphTabs"
        >
          <div *dxTemplate="let tabPanelItem of 'item'">
            <ng-container [ngSwitch]="tabPanelItem">
              <div *ngSwitchCase="graphTabs[0]">
                <div>
                  <margin-reporting [TeamId]="TeamId" [Year]="Year" (onMonthClick)="onMonthChanged($event)" />
                </div>

                <h2>Details on invoices made in {{ MonthLabel }} {{ Year }}</h2>
                <invoice-reporting
                  [InvoicingMonth]="MonthNumber"
                  [TeamId]="TeamId"
                  [InvoicingYear]="this.Year"
                />
              </div>
              <div *ngSwitchCase="graphTabs[1]">
                <div>
                  <time-reporting [TeamId]="TeamId" [Year]="Year" />
                </div>
              </div>
              <div *ngSwitchCase="graphTabs[2]">
                <div>
                  <wip-reporting [TeamId]="TeamId" />
                </div>
              </div>
              <div *ngSwitchCase="graphTabs[3]">
                <div>
                  <budget-reporting [TeamId]="TeamId" />
                </div>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </div>
    </div>
  </div>
</dx-scroll-view>
