import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  DxButtonModule,
  DxFormModule,
  DxLookupComponent,
  DxValidatorModule,
} from 'devextreme-angular';
import { WorkcodeLookupModule } from '../../lookups/workcode-lookup/workcode-lookup.component';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from 'src/environments/environment';
import { FormPopupModule } from '../../utils/form-popup/form-popup.component';
import { RegistrationDetailsFormModule } from '../registration-details-form/registration-details-form.component';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { RegistrationService } from 'src/app/services/time-registration/registration.service';
import { PatriciaService } from 'src/app/services/patricia/patricia.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';

@Component({
  selector: 'time-registration-register-new',
  templateUrl: './time-registration-register-new.component.html',
  styleUrls: ['./time-registration-register-new.component.scss'],
})
export class TimeRegistrationRegisterNewComponent implements OnInit {
  @ViewChild('employeeLookup', { static: false })
  employeeLookup: DxLookupComponent;

  @Input() titleText = '';

  @Input() visible = false;

  @Input() registration?: any;

  @Output() save = new EventEmitter<{
    registration: any;
    detailsList: any;
  }>();

  @Output() visibleChange = new EventEmitter<boolean>();

  employees: any;

  detailDataSource: DataSource;

  detailCustomStore: any;

  detailsList: any = [];

  arrayStore: ArrayStore;

  url = environment.CalystaApiBaseURL + 'api/';

  registrationService: RegistrationService;

  patriciaService: PatriciaService;

  defaultEmployee: number;

  totalHours = 0;
  totalMinutes = 0;
  billableHours = 0;
  billableMinutes = 0;
  nonBillableHours = 0;
  nonBillableMinutes = 0;
  totalBillableAmount = 0;

  constructor(
    registrationService: RegistrationService,
    patriciaService: PatriciaService,
    private employeeService: EmployeeService
  ) {
    this.registrationService = registrationService;
    this.patriciaService = patriciaService;
  }

  ngOnInit(): void {
    this.registration = {};
  }

  handleVisible(e: boolean): void {
    this.visible = e;
    this.registration = {};

    this.clearData();

    this.visibleChange.emit(e);
  }

  onSaveClick(): void {
    this.save.emit({
      registration: this.registration,
      detailsList: this.detailsList,
    });

    this.clearData();
  }

  clearData = () => {
    this.detailDataSource = undefined;
    this.detailsList = [];

    this.arrayStore.clear();
  };

  getTotalHours(): number {
    return this.totalHours ?? this.registration.TotalHours;
  }

  getTotalMinutes(): number {
    return this.totalMinutes ?? this.registration.TotalMinutes;
  }

  getBillableHours(): number {
    return this.billableHours ?? this.registration.BillableHours;
  }

  getBillableMinutes(): number {
    return this.billableMinutes ?? this.registration.BillableMinutes;
  }

  getNonBillableHours(): number {
    return this.nonBillableHours ?? this.registration.NonBillableHours;
  }

  getNonBillableMinutes(): number {
    return this.nonBillableMinutes ?? this.registration.NonBillableMinutes;
  }

  getTotalBillableAmount(): number {
    return this.totalBillableAmount ?? this.registration.TotalBillableAmount;
  }

  onShow() {
    if(this.registration?.EmployeeId == null) {
      this.getDefaultEmployee();
    }

    this.employees = new DataSource({
      store: AspNetData.createStore({
        key: 'employeeId',
        loadUrl: `${this.url}Employee/lookup`,
        onBeforeSend(method, ajaxOptions) {
          if (method == 'load') {
            ajaxOptions.data.ActiveOnly = true;
          }
        },
      }),
      sort: ['FirstName', 'LastName'],
    });

    this.arrayStore = new ArrayStore({
      key: 'RegistrationDetailId',
      data: this.detailsList,
      onInserted: (values: any) => {
        //if this.detailsList does not contain values, then push it in
        //workaround, idk why it's not working
        if (!this.detailsList.includes(values)) {
          this.detailsList.push(values);
        }
      },
    });

    if (this.registration?.RegistrationId) {
      this.detailDataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'RegistrationDetailId',
          loadUrl: `${this.url}TimeRegistration/Registration/Detail/${this.registration.RegistrationId}`,
          insertUrl: `${this.url}TimeRegistration/Registration/Detail`,
          updateUrl: `${this.url}TimeRegistration/Registration/Detail`,
          deleteUrl: `${this.url}TimeRegistration/Registration/Detail`,
          onBeforeSend: (method, ajaxOptions) => {
            ajaxOptions.xhrFields = { withCredentials: false };
            if (this.registration.RegistrationId) {
              ajaxOptions.data.RegistrationId =
                this.registration.RegistrationId;
            }
          },
        }),
      });
    } else {
      this.detailDataSource = new DataSource({
        store: this.arrayStore,
      });
    }
  }

  getDefaultEmployee() {
    this.employeeService.getMyself().subscribe((data) => {
      this.registration.EmployeeId = data.EmployeeId;
    });
  }

  updateTimes(e: {
    totalHours: number;
    totalMinutes: number;
    nonBillableHours: number;
    nonBillableMinutes: number;
    billableHours: number;
    billableMinutes: number;
    totalBillableAmount: number;
  }) {
    this.totalHours = e.totalHours;
    this.totalMinutes = e.totalMinutes;
    this.nonBillableHours = e.nonBillableHours;
    this.nonBillableMinutes = e.nonBillableMinutes;
    this.billableHours = e.billableHours;
    this.billableMinutes = e.billableMinutes;
    this.totalBillableAmount = e.totalBillableAmount;
  }
}

@NgModule({
  declarations: [TimeRegistrationRegisterNewComponent],
  exports: [TimeRegistrationRegisterNewComponent],
  bootstrap: [TimeRegistrationRegisterNewComponent],
  imports: [
    BrowserModule,
    DxFormModule,
    WorkcodeLookupModule,
    FormPopupModule,
    DxValidatorModule,
    DxButtonModule,
    RegistrationDetailsFormModule,
  ],
})
export class TimeRegistrationRegisterNewModule {}
