import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DxChartComponent, DxChartModule } from 'devextreme-angular';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';

@Component({
  selector: 'encoded-time-graph',
  templateUrl: './encoded-time-graph.component.html',
  styleUrls: ['./encoded-time-graph.component.scss']
})
export class EncodedTimeGraphComponent implements OnInit, OnChanges {
  @ViewChild('EncodedTrackChart') chart: DxChartComponent;

  @Input() EmployeeId: number;
  @Input() StartDate: Date;
  @Input() EndDate: Date;
  @Input() TitlePeriod: string = '';
  @Input() ArgumentField: string = 'Month';

  EmployeeTargetValue = 1;
  maxValueMargin: number = 1;

  ChartData: any;

  constructor(private TimeReportingSvc: TimeReportingService) { }

  refreshData() {
    this.chart.instance.showLoadingIndicator();
    this.getEmployeeTarget();
    this.TimeReportingSvc.getEncodedTimeTrack(
      this.EmployeeId,
      this.getDateOnly(this.StartDate),
      this.getDateOnly(this.EndDate),
    ).subscribe((data) => {
      this.ChartData = data;
    },
      (error) => {
        console.error(error);
      },
      () => {
        this.chart.instance.hideLoadingIndicator();
        console.log(this.ArgumentField);
      });
  }

  getEmployeeTarget() {
    let Interval: string = "Month";
    if (this.ArgumentField === "Week") {
      Interval = "Week";
    } else if (this.ArgumentField === "Day") {
      Interval = "Day";
    }

    this.TimeReportingSvc.getEmployeeTarget(this.EmployeeId, this.StartDate.getFullYear(), Interval).subscribe((data) => {
      this.EmployeeTargetValue = data.TargetHours;
      this.maxValueMargin = this.EmployeeTargetValue * 1.2;
    });
  }

  getDateOnly(date: Date): string {
    return date.toDateString();
  }

  ngOnInit() {
    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.refreshData();
    }
  }

}

@NgModule({
  declarations: [EncodedTimeGraphComponent],
  exports: [EncodedTimeGraphComponent],
  imports: [CommonModule, DxChartModule],
})
export class EncodedTimeGraphModule { }

