import {
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxLoadPanelModule,
} from 'devextreme-angular';
import { DxoMasterDetailModule } from 'devextreme-angular/ui/nested';
import DataSource from 'devextreme/data/data_source';
import { TitleBarModule } from 'src/app/components';
import { TimeReportingService } from 'src/app/services/time-registration/reporting.service';
import { InvoiceDetailsReportingModule } from './invoice-details-reporting/invoice-details-reporting.component';
import { ExportingEvent } from 'devextreme/ui/data_grid';

import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

@Component({
  selector: 'invoice-reporting',
  templateUrl: './invoice-reporting.component.html',
  styleUrls: ['./invoice-reporting.component.scss'],
})
export class InvoiceReportingComponent implements OnInit, OnChanges {
  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;

  @Input() InvoicingMonth: number = new Date().getMonth();
  @Input() InvoicingYear: number = new Date().getFullYear();
  @Input() TeamId: number;

  InvoiceReportingDataSource: DataSource;

  constructor(private TimeReportingSvc: TimeReportingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.TeamId != 0) {
        console.log(changes);
        this.loadInvoiceReporting();
      }
    }
  }

  ngOnInit() {
    if (this.TeamId != 0) {
      this.loadInvoiceReporting();
    }
  }

  loadInvoiceReporting() {
    //this.grid.instance.beginCustomLoading('Loading...');
    this.InvoiceReportingDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InvoiceHeaderId',
        loadUrl: `${this.TimeReportingSvc.getUrl()}/TeamReporting/GetInvoiceReporting`,
        loadParams: {
          TeamId: this.TeamId,
          Year: this.InvoicingYear,
          MonthNumber: this.InvoicingMonth,
        },
        onLoaded: (e) => {
          this.grid.instance.endCustomLoading();
        },
      }),
    });
  }

  onExporting(e: ExportingEvent) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('TimesData');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'DataGrid.xlsx'
        );
      });
    });
  }

  onRowUpdating = (e) => {
    e.newData = { ...e.oldData, ...e.newData };
  };
}

@NgModule({
  providers: [],
  exports: [InvoiceReportingComponent],
  declarations: [InvoiceReportingComponent],
  imports: [
    TitleBarModule,
    DxDataGridModule,
    DxoMasterDetailModule,
    InvoiceDetailsReportingModule,
  ],
})
export class InvoiceReportingModule {}
