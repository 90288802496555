import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const apiUrl = environment.CalystaApiBaseURL;

@Injectable({
  providedIn: 'root',
})
export class CRMService {
  url = apiUrl + 'api';
  constructor(private http: HttpClient) {}

  public getFieldStatus(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}/Prospection/FieldStatus/Lookup`);
  }

  getUrl() {
    return this.url;
  }

  insertProspectContact(details: any[], prospectId: string) {
    const body = {
      ProspectContacts: details,
      ProspectId: prospectId,
    };

    return this.http.post<any>(`${this.url}/Prospection/CRM/ProspectContact/Batch`, body);
  }

  markTodoAsDone(
    ProspectActionId: number,
    IsOutcomePositive: boolean,
    OutcomeComments: string,
    DoneDate: Date
  ): Observable<any> {
    return this.http.put<any>(`${this.url}/Prospection/Todo/MarkAsDone/`, {
      ProspectActionId,
      IsOutcomePositive,
      OutcomeComments,
      DoneDate,
    });
  }

  importToPx(
    ProspectToPatriciaRequest: {
      City: string;
      CountryId: string;
      EmailCompany: string;
      PhoneCompany: string;
      Province: string;
      Street: string;
      VatNumber: string;
      ZipCode: string;
      ProspectId: number;
    }
  ) {
    return this.http.post<any>(
      `${this.url}/CRM/Prospect/CreateInPatricia`,
      {ProspectToPatriciaRequest: ProspectToPatriciaRequest}
    );
  }

  getDetailedInfo(id: number, isProspect: boolean) {
    return this.http.get<any>(
      `${this.url}/Prospection/CRM/Details/${id}?IsProspect=${isProspect}`
    );
  }

  getClientOrProspectById(id: number, isProspect: boolean) {
    return this.http.get<any>(
      `${this.url}/Prospection/CRM/ClientsAndProspects/${id}?IsProspect=${isProspect}`
    );
  }

  transfertToPortal(nameId: number) {
    return this.http.post<any>(
      `${this.url}/Prospection/CRM/TransfertToClientPortal/${nameId}`,
      {}
    );
  }
}
