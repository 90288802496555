import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  DxBulletModule,
  DxChartComponent,
  DxChartModule,
  DxLoadPanelModule,
  DxTabsModule,
} from 'devextreme-angular';
import { CardAnalyticsModule } from '../../library/card-analytics/card-analytics.component';
import { Statistics } from 'src/app/types/invoicing/reporting/Statistics';
import { InitializedEvent, PointClickEvent } from 'devextreme/viz/chart';
import { CommonModule } from '@angular/common';
import { SalesAnalyticsService } from 'src/app/services';
import { ItemClickEvent } from 'devextreme/ui/tabs';

@Component({
  selector: 'realized-target-chart',
  templateUrl: './realized-target-chart.component.html',
  styleUrls: ['./realized-target-chart.component.scss'],
})
export class RealizedTargetChartComponent implements OnInit, OnChanges {
  @ViewChild('chart', { static: false }) chart: DxChartComponent;

  @Input() Title: string;
  @Input() ChartData: Statistics[];
  @Input() ContentClass: string = null;
  @Input() isLoading: boolean = false;

  @Input() startDate: string = new Date(new Date().getFullYear(), 0, 1).toDateString();
  @Input() endDate: string = new Date(new Date().getFullYear(), 11, 31).toDateString();

  @Output() onPointClick = new EventEmitter<any>();

  BudgetTabs: Tab[] = [
    {
      id: 1,
      text: 'Global',
      budgetId: null,
    },
    {
      id: 2,
      text: 'Patent',
      budgetId: 1,
    },
    {
      id: 3,
      text: 'Trademarks',
      budgetId: 2,
    },
    {
      id: 4,
      text: 'Renewals',
      budgetId: 5,
    },
    {
      id: 4,
      text: 'Int. Bus. dev.',
      budgetId: 3,
    },
    {
      id: 4,
      text: 'ClientPortal',
      budgetId: 4,
    },
    {
      id: 4,
      text: 'Support',
      budgetId: 6,
    },
  ];

  selectedBudget: number = 0;

  constructor(private salesSvc: SalesAnalyticsService) {}

  ngOnChanges(changes: SimpleChanges): void {

    console.log(changes);
    if(changes.startDate || changes.endDate) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    this.salesSvc.getMarginToRealized(this.startDate, this.endDate).subscribe({
      next: (data) => {
        this.chart.instance.hideLoadingIndicator();
        this.ChartData = data;
      },
      error: (error) => {
        console.error(error);
        this.chart.instance.hideLoadingIndicator();
      },
      complete: () => {
        this.chart.instance.hideLoadingIndicator();
      },
    });
  }

  onChartPointClick(e: PointClickEvent) {
    this.onPointClick.emit(e);
  }

  // This method is called when the input properties of the component change.
  // mainly to handle the loading indicator.
  onTabClick($event: ItemClickEvent) {
    this.chart.instance.showLoadingIndicator();
    this.salesSvc
      .getMarginToRealized(
        this.startDate,
        this.endDate,
        $event.itemData.budgetId
      )
      .subscribe({
        next: (data) => {
          this.chart.instance.hideLoadingIndicator();
          this.ChartData = data;
        },
        error: (error) => {
          console.error(error);
          this.chart.instance.hideLoadingIndicator();
        },
        complete: () => {
          this.chart.instance.hideLoadingIndicator();
        },
      });
  }

  // This method is called when the chart is initialized to check if the data is loaded.
  onChartInitialized($event: InitializedEvent) {
    if (this.isLoading) this.chart.instance.showLoadingIndicator();
  }
}

export class Tab {
  id: number;

  text?: string;

  budgetId: number;
}

@NgModule({
  imports: [
    CardAnalyticsModule,
    DxBulletModule,
    DxChartModule,
    DxLoadPanelModule,
    DxTabsModule,
    CommonModule,
  ],
  declarations: [RealizedTargetChartComponent],
  exports: [RealizedTargetChartComponent],
})
export class RealizedTargetChartModule {}
